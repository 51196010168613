/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import {
  postLogin, postRegister,
} from '../requests/api/auth';
import { getUserById } from '../requests/api/users';
import { UNKNOWN_ERROR } from '../constants/errors';
import { TRANSBANK } from '../constants/payment';

const getAccessToken = () => localStorage.getItem('accessToken');

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { id } = jwtDecode(accessToken);
    localStorage.setItem('userId', id);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAxiosInterceptors = ({ onLogout }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (onLogout) {
          setSession(null);
          onLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

const loginWithEmailAndPassword = async (email, password, source) => {
  try {
    const response = await postLogin({
      email,
      password,
      source,
    });
    if (response.success && response.user) {
      const { token, user, tbkToken } = response;
      if (window.SmartPOS && window.SmartPOS.getAdquirente() === TRANSBANK && tbkToken) {
        window.SmartPOS.setTbkApiKey(tbkToken);
      }
      setSession(token);
      return {
        user,
        success: true,
      };
    }
    setSession(null);
    return {
      success: false,
      errorMessage: response.data.error,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const logoutSession = () => {
  setSession(null);
};

const loginInWithToken = async () => {
  const accessToken = getAccessToken();
  const { id } = jwtDecode(accessToken).user;
  try {
    const response = await getUserById(id);
    if (!response.success) {
      logoutSession();
      return null;
    }
    return (response.user) || null;
  } catch (err) {
    return null;
  }
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleAuthentication = () => {
  // localStorage.clear();
  const accessToken = getAccessToken();
  if (!accessToken) {
    setSession(null);
    return;
  }

  if (isValidToken(accessToken)) {
    setSession(accessToken);
  } else {
    setSession(null);
  }
};

const register = async (name, lastname, email, password) => {
  const role = 'admin';
  try {
    const response = await postRegister({
      name, lastname, email, password, role,
    });

    if (response.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const isAuthenticated = () => !!getAccessToken();

export default {
  setAxiosInterceptors,
  loginWithEmailAndPassword,
  loginInWithToken,
  logoutSession,
  handleAuthentication,
  isAuthenticated,
  setSession,
  register,
};
