/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box, MenuItem, Typography, CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import paymentMethods, { PAYMENT_IN_DEBT } from '../../../utils/const/paymentMethods';
import useStyles from '../styles';
import thousandSeparator from '../../../utils/thousandSeparator';
import CustomTextField from '../../../components/Material/CustomTextfieldV2';
import { addPaymentsCart, updatePaymentsCart } from '../../../actions/cartActions';
import handleApiResponse from '../../../utils/handleApiResponse';
import { PAYMENT_APPROVED, PAYMENT_PENDING, PAYMENT_REJECTED } from '../../../utils/const/status';
import { cancelOrder, putCompleteOrder } from '../../../requests/api/orders';
import { postNewPayment, putPayment, patchPaymentResponse } from '../../../requests/api/payments';
import { SALE } from '../../../utils/const/reasonStockChange';
import { TRANSBANK, COMPRAQUI } from '../../../constants/payment';
import { resetOrderId } from '../../../actions/sessionActions';
import CustomButton from '../../../components/Material/CustomButton';
import CustomTitleTypography from '../../../components/Material/CustomTitlesTypography';
import { postNewDebt } from '../../../requests/api/debts';

function Payment({
  // setIsLoadingPayment,
  setIsLoadingBill,
}) {
  const classes = useStyles();
  const isMounted = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const { paymentsCart, totalCartAmount } = useSelector((state) => state.cart);
  const {
    createdOrderId,
    moduleInformation,
  } = useSelector((state) => state.session);
  const { clientId } = useSelector((state) => state.account.user);
  const { customer } = useSelector((state) => state.customer);

  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentIndex, setPaymentIndex] = useState(0);
  // const [voucherInfo, setVoucherInfo] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountLeft, setAmountLeft] = useState(0);

  const [openSelect, setOpenSelect] = useState(false);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const handlePay = async () => {
    // Check payment selected
    console.log('handlePay()');
    console.log('selectedPayment: ', selectedPayment);
    console.log('moduleInformation: ', moduleInformation);

    // return;

    // call redelcom and get response from webhook - need to use this
    // to update state accordingly
    // need to change verification to redelcom

    // if (!moduleInformation.rdcPayment && voucherInfo.trim() === '') {
    //   enqueueSnackbar('Debes ingresar información del voucher', {
    //     variant: 'error',
    //   });
    //   return;
    // }

    // Monto no debe ser mayor de lo que queda por pagar y debe ser mayor a 0
    if ((Number(amountToPay) < 0) || (Number(amountToPay) > Number(amountLeft))) {
      enqueueSnackbar('Monto a pagar debe ser mayor a 0 y menor al monto restante de pago', {
        variant: 'error',
      });
      setAmountToPay(amountLeft);
      return;
    }

    // // Make the payment in database
    const auxPaymentsCart = (paymentsCart && [...paymentsCart]) || [];

    try {
      let newPaymentOrDebt = null;
      if (!moduleInformation.rdcPayment) {
        if (selectedPayment === PAYMENT_IN_DEBT) {
          const { newDebt } = await postNewDebt({
            orderId: createdOrderId,
            initialDebt: amountToPay !== totalCartAmount ? amountToPay : totalCartAmount,
            clientId,
          });
          newPaymentOrDebt = newDebt;
        } else {
          const { newPayment } = await postNewPayment({
            clientId,
            amount: amountToPay !== totalCartAmount ? amountToPay : totalCartAmount,
            status: PAYMENT_PENDING,
            orderId: createdOrderId,
            type: selectedPayment,
            depositId: '',
            errorMessage: '',
            authCode: '',
            customerId: (customer && customer.id) || '',
          });

          // Open Payment App
          if (selectedPayment === 'TARJETA_CREDITO' || selectedPayment === 'TARJETA_DEBITO') {
            if (window.SmartPOS && window.AppPagoSDK && window.SmartPOS.getAdquirente() === TRANSBANK) {
              window.SmartPOS.tbkRequestForSales(newPayment.payment.id, amountToPay.toString());
              setIsLoadingPayment(true);
            } else if (window.SmartPOS && window.SmartPOS.getAdquirente() === COMPRAQUI) {
              window.SmartPOS.caqRequestForSales(newPayment.payment.id, amountToPay.toString());
              setIsLoadingPayment(true);
            } else {
              throw new Error('No payment provider selected');
            }
          } else {

          }

          newPaymentOrDebt = newPayment.payment;
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('check RDC Payment, not implemented yet');
        throw new Error('RDC Payement not implemented yet');
      }

    //   if (amountLeft > amountToPay) {
    //     // agregar o crear el paymentCart
    //     if (auxPaymentsCart.length > 0) {
    //       auxPaymentsCart[paymentIndex] = {
    //         ...auxPaymentsCart[paymentIndex],
    //         amount: Number(newPaymentOrDebt.amount),
    //         status: newPaymentOrDebt.status,
    //         type: newPaymentOrDebt.type,
    //       };
    //       dispatch(updatePaymentsCart(auxPaymentsCart));
    //     } else {
    //       auxPaymentsCart[paymentIndex] = {
    //         amount: Number(newPaymentOrDebt.amount),
    //         status: newPaymentOrDebt.status,
    //         type: newPaymentOrDebt.type,
    //       };
    //       dispatch(addPaymentsCart(auxPaymentsCart));
    //     }

    //     setPaymentIndex(paymentIndex + 1);
    //     const totalPaid = auxPaymentsCart
    //       .filter((item) => item.status === PAYMENT_APPROVED)
    //       .reduce((accum, value) => accum + Number(value.amount), 0);
    //     setAmountToPay(totalAmount - totalPaid);
    //     setAmountLeft(totalAmount - totalPaid);
    //     return;
    //   }
    //   auxPaymentsCart[paymentIndex] = {
    //     amount: Number(newPaymentOrDebt.amount),
    //     status: newPaymentOrDebt.status,
    //     type: newPaymentOrDebt.type,
    //   };
    } catch (err) {
      console.log('err.message: ', err.message);
      console.log('err.stack: ', err.stack);
      history.push({
        pathname: '/cart/error',
        state: { errorMessage: 'Hubo problemas con uno de los pagos' },
      });
      handleApiResponse(enqueueSnackbar, err, false);
      return;
    }

    // // All payments done
    // if (moduleInformation.eBilling) {
    //   setIsLoadingBill(true);
    // }

    // try {
    //   await putCompleteOrder({
    //     orderId: createdOrderId,
    //     reason: SALE,
    //     // voucherInfo: voucherInfo || null,
    //   });
    // } catch (err) {
    //   handleApiResponse(enqueueSnackbar, err, false);
    //   return;
    // }

    // if (auxPaymentsCart.every((item) => item.status === PAYMENT_APPROVED)) {
    //   // if (!moduleInformation.eBilling) {
    //   //   enqueueSnackbar('Tu pedido se ha realizado con éxito', {
    //   //     variant: 'success',
    //   //   });
    //   //   history.push('/store-options');
    //   // } else {
    //   //   history.push('/cart/success');
    //   // }
    //   history.push('/cart/success');
    // } else {
    //   history.push({
    //     pathname: '/cart/error',
    //     state: { errorMessage: 'No están aprobados todos los pagos' },
    //   });
    // }
  };

  const handleCancelPayment = async () => {
    try {
      await cancelOrder({ orderId: createdOrderId });
      dispatch(resetOrderId());
      history.push('/store-options');
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleTransankPaymentResponse = async (paymentId, response) => {
    console.log('handleTransbankPaymentResponse(): ', response);
    console.log('paymentId: ', paymentId);
    console.log('success: ', response.success);
    console.log('code: ', response.code);
    console.log('message: ', response.message);

    if (response.success) {
      const data = JSON.parse(response.data);

      console.log('data.abreviacionTarjeta: ', data.abreviacionTarjeta);
      console.log('data.codigoAutorización: ', data.codigoAutorización);
      console.log('data.codigoComercio: ', data.codigoComercio);
      console.log('data.codigoRespuesta: ', data.codigoRespuesta);
      console.log('data.empleado: ', data.empleado);
      console.log('data.fechaTransacción: ', data.fechaTransacción);
      console.log('data.glosaRespuesta: ', data.glosaRespuesta);
      console.log('data.horaTransacción: ', data.horaTransacción);
      console.log('data.medioPago: ', data.medioPago);
      console.log('data.moneda: ', data.moneda);
      console.log('data.monto: ', data.monto);
      console.log('data.montoCuota: ', data.montoCuota);
      console.log('data.numeroBoleta: ', data.numeroBoleta);
      console.log('data.numeroCuenta: ', data.numeroCuenta);
      console.log('data.numeroCuotas: ', data.numeroCuotas);
      console.log('data.numeroOperacion: ', data.numeroOperacion);
      console.log('data.propina: ', data.propina);
      console.log('data.terminalID: ', data.terminalID);
      console.log('data.tipoDocumento: ', data.tipoDocumento);
      console.log('data.ultimos4DigitosTarjeta: ', data.ultimos4DigitosTarjeta);
      console.log('data.vuelto: ', data.vuelto);

      await patchPaymentResponse({
        paymentId: paymentId,
        status: PAYMENT_APPROVED,
        errorMessage: null,
        authCode: data.codigoAutorización,
      });
      setIsLoadingPayment(false);
      history.push('/cart/success');
    } else {
      await patchPaymentResponse({
        paymentId: paymentId,
        status: PAYMENT_REJECTED,
        errorMessage: response.message,
        authCode: null,
      });
      setIsLoadingPayment(false);
      if (response.data && response.data.codigoRespuesta && response.data.glosaRespuesta) {
        history.push({
          pathname: '/cart/error',
          state: { errorMessage: `Pago Rechazado: (${response.data.codigoRespuesta}) ${response.message} - ${response.data.glosaRespuesta}` },
        });
      } else {
        history.push({
        pathname: '/cart/error',
        state: { errorMessage: `Pago Rechazado: ${response.message}` },
        });
      }
      return;
    }
  };

  useEffect(() => {
    // Assuming always sequential payments
    let relevantIndex = 0;
    let indexBoolean = false;
    let totalAmountPaid = 0;

    if (paymentsCart) {
      for (let i = 0; i < paymentsCart.length; i += 1) {
        if (paymentsCart[i].status === PAYMENT_PENDING && !indexBoolean) {
          indexBoolean = true;
        }
        if (paymentsCart[i].status === PAYMENT_APPROVED) {
          totalAmountPaid += paymentsCart[relevantIndex].amount;
        }
        if (!indexBoolean) {
          relevantIndex += 1;
        }
      }
      setPaymentIndex(relevantIndex);
      setSelectedPayment(paymentMethods[0].type);
      setTotalAmount(totalCartAmount);
      setAmountToPay(totalCartAmount - totalAmountPaid);
      setAmountLeft(totalCartAmount - totalAmountPaid);
    } else {
      setPaymentIndex(0);
      setSelectedPayment(paymentMethods[0].type);
      setTotalAmount(totalCartAmount);
      setAmountToPay(totalCartAmount);
      setAmountLeft(totalCartAmount);
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setSelectedPayment(paymentMethods[0].type);
    } else {
      isMounted.current = true;
    }
  }, [paymentIndex]);

  useEffect(() => {
    window.handlePaymentResponse = async (paymentId, response) => {
      if (window.SmartPOS.getAdquirente() === TRANSBANK) {
        await handleTransankPaymentResponse(paymentId, response);
      }
    }
  }, []);

  return (
    <Box
      display='flex'
      flex='1'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Typography
          variant='h4'
          className={classes.blueText}
        >
          Deuda restante
        </Typography>
        <Typography
          variant='h2'
          className={classes.orangeText}
        >
          {`$ ${thousandSeparator((amountLeft) || 0, '.')}`}
        </Typography>
        {(totalAmount - amountLeft) !== 0
          && <CustomTitleTypography
            firstHighlight='Pagado:'
            secondHighlight={`$ ${thousandSeparator(totalAmount - amountLeft || 0, '.')}`}
            blueFirst
            fontSize='18px'
          />

        }
      </Box>
      <Box
        width='100%'
        display='flex'
        mt={2}
        mb={2}
      >
        {isLoadingPayment ? (
          <Box
            width='100%'
            display='flex'
            flex='1'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <CircularProgress style={{ color: 'var(--brand-blue' }} />
          </Box>
        ) : (
          <Box
            width='100%'
            display='flex'
            flex='1'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Box
              width='100%'
              maxWidth='400px'
              display='flex'
              mt={2}
              mb={2}
            >
              <CustomTextField
                color="secondary"
                onChange={(e) => setAmountToPay(e.target.value)}
                value={amountToPay}
                variant="outlined"
                fullWidth
                disabled
              />
            </Box>
            <Box
              width='100%'
              maxWidth='400px'
              display='flex'
              mt={2}
              mb={2}
            >
              <CustomTextField
                color="secondary"
                onChange={(e) => setSelectedPayment(e.target.value)}
                value={selectedPayment}
                select
                variant="outlined"
                fullWidth
                onClick={() => setOpenSelect(!openSelect)}
                SelectProps={{
                  open: openSelect,
                }}
                className={classes.marginLeft}
              >
                {customer && customer.id && paymentMethods.map((option, index) => (
                  <MenuItem key={index} value={option.type}>
                    {option.label}
                  </MenuItem>
                ))}
                {!customer && paymentMethods
                  .filter((item) => item.type !== PAYMENT_IN_DEBT)
                  .map((option, index) => (
                    <MenuItem key={index} value={option.type}>
                      {option.label}
                    </MenuItem>
                  ))}
              </CustomTextField>
            </Box>
            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              {/* {!moduleInformation.rdcPayment
                && (
                  <Box
                    width='100%'
                    maxWidth='400px'
                  >
                    <InputLabel
                      className={classes.inputLabel}
                    >
                      Voucher venta
                    </InputLabel>
                    <CustomTextField
                      color="secondary"
                      onChange={(e) => setVoucherInfo(e.target.value)}
                      value={voucherInfo}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                )
              } */}
              <Box
                width='100%'
                maxWidth='400px'
                mt={2}
              >
                <CustomButton
                  type="submit"
                  size="large"
                  variant="contained"
                  color='primary'
                  onClick={handlePay}
                  buttoncolor='blue'
                >
                  {/* {!moduleInformation.rdcPayment
                    ? 'Pagado'
                    : 'Pagar'
                  } */}
                  Pagar
                </CustomButton>
              </Box>
              <Box
                width='100%'
                maxWidth='400px'
                mt={2}
              >
                <CustomButton
                  type="submit"
                  size="large"
                  variant="contained"
                  color='primary'
                  onClick={handleCancelPayment}
                >
                  Cancelar
                </CustomButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box >
  );
}

export default Payment;
